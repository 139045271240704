import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../styles/register.css";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../redux/reducers/rootSlice";
import jwt_decode from "jwt-decode";
import fetchData from "../helper/apiCall";
// import Navbar from "../components/Navbar";
import Header from "../layout/header";
import Footer from "../layout/footer";

// axios.defaults.baseURL = process.env.REACT_APP_SERVER_DOMAIN;

function Login() {
  const dispatch = useDispatch();
  const [formDetails, setFormDetails] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const inputChange = (e) => {
    const { name, value } = e.target;
    return setFormDetails({
      ...formDetails,
      [name]: value,
    });
  };

  const formSubmit = async (e) => {
    try {
      e.preventDefault();
      const { email, password } = formDetails;
      if (!email || !password) {
        return toast.error("Input field should not be empty");
      } else if (password.length < 5) {
        return toast.error("Password must be at least 5 characters long");
      }

      const { data } = await toast.promise(
        axios.post("/user/login", {
          email,
          password,
        }),
        {
          pending: "Logging in...",
          success: "Login successfully",
          error: "Unable to login user",
          loading: "Logging user...",
        }
      );
      console.log("Logged in....=================",data);
      localStorage.setItem("token", data.token);
      let decodedData = jwt_decode(data.token);
      localStorage.setItem("firstName", decodedData?.userData?.firstname);
      localStorage.setItem("lastName", decodedData?.userData?.lastname);

      // console.log("decodedData=================",decodedData);

      dispatch(setUserInfo(jwt_decode(data.token).userId));
      getUser(jwt_decode(data.token).userId);
    } catch (error) {
      return error;
    }
  };

  const getUser = async (id) => {
    try {
      const temp = await fetchData(`${process.env.REACT_APP_AUTH_URL}/api/user/getuser/${id}`);
      dispatch(setUserInfo(temp));
      return navigate("/");
    } catch (error) {
      return error;
    }
  };

  return (
    <>
      <Header />
      <section className="register-section flex-center min-height-50">
        <div className="register-container flex-center">
          <h2 className="form-heading">Sign In</h2>
          <form
            onSubmit={formSubmit}
            className="register-form"
          >
            <input
              type="email"
              name="email"
              className="form-input"
              placeholder="Enter your email"
              value={formDetails.email}
              onChange={inputChange}
            />
            <input
              type="password"
              name="password"
              className="form-input"
              placeholder="Enter your password"
              value={formDetails.password}
              onChange={inputChange}
            />
            <button
              type="submit"
              className="btn form-btn primary-btn"
            >
              sign in
            </button>
          </form>
          <p className="d-flex flex-col text-center">
            <NavLink to={"/forgot-password"} className="mb-1 primary-font">Forgot Password? </NavLink>
            <div>
              Not a user?{" "}
              <NavLink
                className="login-link primary-font"
                to={"/register"}
              >
                Register
              </NavLink>
            </div>
          </p>
        </div>
      </section>
      <Footer />
    </>
    
  );
}

export default Login;
