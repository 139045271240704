import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../../redux/reducers/rootSlice";
import jwt_decode from "jwt-decode";
import axios from "axios";

const MainMenu = () => {

    const [iconActive, setIconActive] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [token, setToken] = useState(localStorage.getItem("token") || "");
    const [user, setUser] = useState(
        localStorage.getItem("token")
        ? jwt_decode(localStorage.getItem("token"))
        : ""
    );
    const [isNewNotification, setIsNewNotification] = useState(false);

    const logoutFunc = () => {
        axios.post("/doctor/logoutDoctor",{user},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        ).then((data)=>{
          console.log("Logout response===============",data);
          dispatch(setUserInfo({}));
          localStorage.removeItem("token");
          window.location.reload();
          navigate("/login");
        });
    };

    return (
        <nav>
            <ul className="main-menu">
                <li>
                    <NavLink
                        className="main-menu-link"
                        // activeClassName="active"
                        // exact
                        to="/"
                    >
                        Home
                    </NavLink>
                </li>
                {/* <li>
                    <NavLink
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/doctors"}
                    >
                        Nurses
                    </NavLink>
                </li> */}

                {!token &&
                <li>
                    <NavLink
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/login"}
                    >
                        Login
                    </NavLink>
                </li>}
                
                {token && !user.isAdmin && (
                    <>
                        <li>
                            <NavLink
                                className="main-menu-link"
                                to={process.env.PUBLIC_URL + "/appointments"}
                            >
                                Appointments
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="main-menu-link"
                                to={process.env.PUBLIC_URL + "/notifications"}
                            >
                                Notifications
                            </NavLink>
                        </li>
                        {!user?.isDoctor && <li>
                            <NavLink
                                className="main-menu-link"
                                to={process.env.PUBLIC_URL + "/applyfordoctor"}
                            >
                                Register as a Consultant
                            </NavLink>
                        </li>}
                        <li>
                            <NavLink
                                className="main-menu-link"
                                to={process.env.PUBLIC_URL + "/profile"}
                            >
                                Profile
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="main-menu-link"
                                to={process.env.PUBLIC_URL + "/payments"}
                            >
                                Payments
                            </NavLink>
                        </li>
                    </>
                )}

                {token &&
                    <li>
                        <NavLink
                            className="main-menu-link"
                            to={process.env.PUBLIC_URL + "/login"}
                            onClick={logoutFunc}
                        >
                            Logout
                        </NavLink>
                    </li>
                }

                {token && user.isAdmin &&
                    <li>
                        <NavLink
                            className="main-menu-link"
                            to={process.env.PUBLIC_URL + "/dashboard/users"}
                        >
                            Dashboard
                        </NavLink>
                    </li>
                }

                {/* <li>
                    <NavLink
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/about"}
                    >
                        About
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/contact"}
                    >
                        Contact
                    </NavLink>
                </li> */}
            </ul>
        </nav>
    );
};

export default MainMenu;
