import React, { useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import "../styles/register.css";
import axios from "axios";
import toast from "react-hot-toast";
import Header from "../layout/header";
import Footer from "../layout/footer";

const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { token } = useParams();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            console.error('Passwords do not match');
            toast.error('Passwords do not match');
            return;
        }

        try {
            const response = await axios.post(`/user/resetpassword/${token}`, {
                password,
            });

            if (response.status === 200) {
                toast.success('Password reset successful');
                window.location.href="/login";
            } else {
                toast.error('Error resetting password');
            }
        } catch (error) {
            console.error('Error resetting password', error);
        }
    };

    return (
        <>
            <Header />
            <section className="register-section flex-center min-height-50">
                <div className="register-container flex-center">
                    <h2 className="form-heading">Reset Password</h2>
                    <form
                        onSubmit={handleSubmit}
                        className="register-form"
                    >
                        <input
                            type="password"
                            name="password"
                            className="form-input"
                            placeholder="Enter your new password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />

                        <input
                            type="password"
                            name="confirmPassword"
                            className="form-input"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        
                        <button
                            type="submit"
                            className="btn form-btn primary-btn"
                        >
                            Reset Password
                        </button>
                    </form>
                </div>
            </section>
            <Footer />
            </>
    );
};

export default ResetPassword;