import PropTypes from "prop-types";
import { NavLink, useNavigate } from "react-router-dom";
import { getClosest, getSiblings, slideToggle, slideUp } from "../../../utils";
import SocialIcon from "../../social-icon";
import { useState } from "react";
import { useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { setUserInfo } from "../../../redux/reducers/rootSlice";

const MobileMenu = ({ show, onClose }) => {
    const onClickHandler = (e) => {
        const target = e.currentTarget;
        const parentEl = target.parentElement;
        if (
            parentEl?.classList.contains("menu-expand") ||
            target.classList.contains("menu-expand")
        ) {
            const element = target.classList.contains("icon")
                ? parentEl
                : target;
            const parent = getClosest(element, "li");
            const childNodes = parent.childNodes;
            const parentSiblings = getSiblings(parent);
            parentSiblings.forEach((sibling) => {
                const sibChildNodes = sibling.childNodes;
                sibChildNodes.forEach((child) => {
                    if (child.nodeName === "UL") {
                        slideUp(child, 1000);
                    }
                });
            });
            childNodes.forEach((child) => {
                if (child.nodeName === "UL") {
                    slideToggle(child, 1000);
                }
            });
        }
    };

    const [iconActive, setIconActive] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [token, setToken] = useState(localStorage.getItem("token") || "");
    const [user, setUser] = useState(
        localStorage.getItem("token")
            ? jwt_decode(localStorage.getItem("token"))
            : ""
    );
    const [isNewNotification, setIsNewNotification] = useState(false);

    const logoutFunc = () => {
        axios.post("/doctor/logoutDoctor", { user },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        ).then((data) => {
            console.log("Logout response===============", data);
            dispatch(setUserInfo({}));
            localStorage.removeItem("token");
            window.location.reload();
            navigate("/login");
        });
    };

    return (
        <div
            className={`offcanvas offcanvas-mobile-menu ${show ? "offcanvas-open" : ""
                }`}
        >
            <div className="inner">
                <div className="border-bottom mb-3 pb-3 text-end">
                    <button className="offcanvas-close" onClick={onClose}>
                        ×
                    </button>
                </div>
                <div className="offcanvas-head mb-3">
                    <div className="header-top-offcanvas">
                        <p>
                            <i className="icofont-google-map"></i>{" "}
                            <span>ADDRESS:</span> India
                        </p>
                    </div>
                </div>
                <nav className="offcanvas-menu">
                    <ul>
                        <li>
                            <NavLink to={"/"}>
                                <span className="menu-text">Home</span>
                            </NavLink>

                            {!token &&
                                <li>
                                    <NavLink
                                        className="menu-text"
                                        to={process.env.PUBLIC_URL + "/login"}
                                    >
                                        Login
                                    </NavLink>
                                </li>}

                            {token && !user.isAdmin && (
                                <>
                                    <li>
                                        <NavLink
                                            className="menu-text"
                                            to={process.env.PUBLIC_URL + "/appointments"}
                                        >
                                            Appointments
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="menu-text"
                                            to={process.env.PUBLIC_URL + "/notifications"}
                                        >
                                            Notifications
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="menu-text"
                                            to={process.env.PUBLIC_URL + "/applyfordoctor"}
                                        >
                                            Register as a Consultant
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="menu-text"
                                            to={process.env.PUBLIC_URL + "/profile"}
                                        >
                                            Profile
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="main-menu-link"
                                            to={process.env.PUBLIC_URL + "/payments"}
                                        >
                                            Payments
                                        </NavLink>
                                    </li>
                                </>
                            )}

                            {token &&
                                <li>
                                    <NavLink
                                        className="menu-text"
                                        to={process.env.PUBLIC_URL + "/login"}
                                        onClick={logoutFunc}
                                    >
                                        Logout
                                    </NavLink>
                                </li>
                            }

                            {token && user.isAdmin &&
                                <li>
                                    <NavLink
                                        className="menu-text"
                                        to={process.env.PUBLIC_URL + "/dashboard/users"}
                                    >
                                        Dashboard
                                    </NavLink>
                                </li>
                            }
                            {/* <span
                                className="menu-expand"
                                onClick={onClickHandler}
                                aria-hidden="true"
                            ></span>
                            <ul className="offcanvas-submenu">
                                <li>
                                    <NavLink
                                        // exact
                                        to={process.env.PUBLIC_URL + "/"}
                                    >
                                        Home 1
                                    </NavLink>
                                </li>
                            </ul> */}
                        </li>
                        {/* <li>
                            <NavLink to={process.env.PUBLIC_URL + "/service"}>
                                <span className="menu-text">Services</span>
                            </NavLink>
                            <span
                                className="menu-expand"
                                onClick={onClickHandler}
                                aria-hidden="true"
                            ></span>
                            <ul className="offcanvas-submenu">
                                <li>
                                    <NavLink
                                        to={process.env.PUBLIC_URL + "/service"}
                                    >
                                        Service
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={
                                            process.env.PUBLIC_URL +
                                            "/service-detalis"
                                        }
                                    >
                                        service details
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <NavLink to={process.env.PUBLIC_URL + "/blog"}>
                                <span className="menu-text">blog</span>
                            </NavLink>
                            <span
                                className="menu-expand"
                                onClick={onClickHandler}
                                aria-hidden="true"
                            ></span>
                            <ul className="offcanvas-submenu">
                                <li>
                                    <NavLink
                                        to={process.env.PUBLIC_URL + "/blog"}
                                    >
                                        Blog list
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={
                                            process.env.PUBLIC_URL +
                                            "/blog-details/1"
                                        }
                                    >
                                        Blog details
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <NavLink to={process.env.PUBLIC_URL + "/about"}>
                                about
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to={process.env.PUBLIC_URL + "/contact"}>
                                Contact Us
                            </NavLink>
                        </li> */}
                    </ul>
                </nav>
                <div className="offcanvas-social my-4">
                    <ul>
                        <li>
                            <SocialIcon
                                path="https://twitter.com/"
                                icon="icofont-twitter"
                            />
                        </li>
                        <li>
                            <SocialIcon
                                path="https://www.facebook.com/profile.php?id=100095220246316&mibextid=ZbWKwL"
                                icon="icofont-facebook"
                            />
                        </li>
                        <li>
                            <SocialIcon
                                path="https://instagram.com/sncom.in?igshid=NzZlODBkYWE4Ng=="
                                icon="icofont-instagram"
                            />
                        </li>
                        {/* <li>
                            <SocialIcon
                                path="https://rss.com/"
                                icon="icofont-rss-feed"
                            />
                        </li> */}
                        <li>
                            <SocialIcon
                                path="https://www.youtube.com/"
                                icon="icofont-play-alt-1"
                            />
                        </li>
                    </ul>
                </div>

                <ul className="media-wrap">
                    <li className="media media-list">
                        <span className="media-icon">
                            <i className="icofont-clock-time"></i>
                        </span>
                        <div className="media-content">
                            <span className="media-sub-heading">
                                working hours
                            </span>
                            <span className="media-heading">
                                24/7 All Week
                            </span>
                        </div>
                    </li>

                    <li className="media media-list">
                        <span className="media-icon">
                            <i className="icofont-whatsapp"></i>
                        </span>
                        <div className="media-content">
                            <span className="media-sub-heading">
                                Whatsapp No.
                            </span>
                            <a
                                className="media-heading"
                                href="https://wa.me/919970141360"
                            >
                                9970141360
                            </a>
                        </div>
                    </li>

                    <li className="media media-list">
                        <span className="media-icon">
                            <i className="icofont-envelope"></i>
                        </span>
                        <div className="media-content">
                            <span className="media-sub-heading">email us</span>
                            <a
                                className="media-heading"
                                href="mailto:support@sncom.in"
                            >
                                support@sncom.in
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};

MobileMenu.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
};

export default MobileMenu;
