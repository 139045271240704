import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";

const Button = ({ classOption, text, path, onClick }) => {
    const handleClick = (event) => {
        if (onClick) {
            onClick(event);
        }
    };
    
    return (
        <React.Fragment>
            <Link
                to={process.env.PUBLIC_URL + path}
                className={`${classOption}`}
                onClick={handleClick}
            >
                {text}
            </Link>
        </React.Fragment>
    );
};

Button.propTypes = {
    classOption: PropTypes.string,
    text: PropTypes.string,
    path: PropTypes.string,
    onClick: PropTypes.func,
};
Button.defaultProps = {
    classOption: "btn",
};

export default Button;
