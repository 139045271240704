import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../styles/register.css";
import axios from "axios";
import toast from "react-hot-toast";
import Header from "../layout/header";
import Footer from "../layout/footer";

const ForgotPassword = () => {
    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Implement logic to send a request to the server
        // with the user's email for initiating password reset
        try {
            const response = await fetch('/forgot-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                console.log('Password reset email sent successfully');
                // Optionally, you can redirect the user to a success page
            } else {
                console.error('Error sending password reset email');
                // Handle error and provide feedback to the user
            }
        } catch (error) {
            console.error('Error sending password reset email', error);
        }
    };

    const formSubmit = async (e) => {
        try {
          e.preventDefault();
          const { data } = await toast.promise(
            axios.post("/user/forgotpassword", {
              email,
            }),
            {
              pending: "Sending reset password link...",
              success: "Reset password link sent successfully. Please check your email.",
              error: "User not found",
              loading: "Sending reset password link...",
            }
          );
        //   console.log("Data============",data?.resetLink);
        //   window.location.href = data?.resetLink;
        } catch (error) {
            console.log("Dataerror============",error?.response?.data?.error);
            // toast.error(error?.response?.data?.error);
            return error;
        }
    }

    return (
        <>
            <Header />
            <section className="register-section flex-center min-height-50">
                <div className="register-container flex-center">
                    <h2 className="form-heading">Forgot Password?</h2>
                    <form
                        onSubmit={formSubmit}
                        className="register-form"
                    >
                        <input
                        type="email"
                        name="email"
                        className="form-input"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        />
                        
                        <button
                            type="submit"
                            className="btn form-btn primary-btn"
                        >
                            Reset Password
                        </button>
                    </form>
                </div>
            </section>
            <Footer />
            </>
    );
};

export default ForgotPassword;