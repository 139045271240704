import SocialIcon from "../../components/social-icon";
import { Link } from "react-router-dom";
// import Newsletter from "../../components/newsletter";
import Logo from "../../components/logo";

const Footer = () => {
    return (
        <footer className="footer-area">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-md-5 col-lg-4 col-xl-3">
                        <div className="widget-item">
                            <div className="about-widget">
                                <div className="logo_class">
                                    <div className="header-logo mb-1">
                                        <Logo
                                            image={`${process.env.PUBLIC_URL}/img/sncom_logo.png`}
                                        />
                                    </div>
                                    <h2 className="mb-2">Sncom Private Limited</h2>
                                    <p className="mb-0">
                                        Your Trusted SN COM
                                    </p>
                                </div>
                                {/* <ul className="widget-contact-info">
                                    <li className="info-address">
                                        <i className="icofont-location-pin"></i>
                                        Sambhaji Nagar, lonikalbhor,Pune, Maharashtra, India 412201
                                    </li>
                                    <li className="info-mail">
                                        <i className="icofont-email"></i>
                                        <a href="mailto:support@sncom.in">
                                            support@sncom.in
                                        </a>
                                    </li>
                                    <li className="info-phone">
                                        <i class="icofont-whatsapp"></i>
                                        <a href="https://wa.me/919970141360">
                                            9970141360
                                        </a>
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-6 offset-md-1 col-lg-3 offset-lg-0 col-xl-3 d-lg-none d-xl-block">
                        <div className="widget-item d-inline-block">
                            <h4 className="widget-title line-bottom mb-2">
                                Contact Us
                            </h4>

                            <div className="">
                                <ul className="widget-contact-info">
                                    <li className="info-address">
                                        <i className="icofont-location-pin"></i>
                                        Sambhaji Nagar, lonikalbhor,Pune, Maharashtra, India 412201
                                    </li>
                                    <li className="info-mail">
                                        <i className="icofont-email"></i>
                                        <a href="mailto:support@sncom.in">
                                            support@sncom.in
                                        </a>
                                    </li>
                                    <li className="info-phone">
                                        <i class="icofont-whatsapp"></i>
                                        <a href="https://wa.me/919970141360">
                                            9970141360
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-6 offset-md-1 col-lg-4 offset-lg-0 col-xl-3 footer_class">
                        <div className="widget-item">
                            <h4 className="widget-title line-bottom mb-2">
                                Company Policies
                            </h4>
                            <div className="widget-contact-info">
                                <ul>
                                    <li>
                                        <a href="/terms-and-conditions">Terms and Conditions</a>
                                    </li>
                                    <li>
                                        <a href="/privacy-policy">Privacy Policy</a>
                                    </li>
                                    <li>
                                        <a href="/refund-policy">Refund Policy</a>
                                    </li>
                                </ul>
                            </div>
                            {/* <div className="widget-social-icons">
                                <SocialIcon
                                    path="https://twitter.com/"
                                    icon="icofont-twitter"
                                />
                                <SocialIcon
                                    path="https://myaccount.google.com/"
                                    icon="icofont-google-plus"
                                />
                                <SocialIcon
                                    path="https://www.youtube.com/@sncom500"
                                    icon="icofont-youtube-play"
                                />
                                <SocialIcon
                                    path="https://www.facebook.com/profile.php?id=100095220246316&mibextid=ZbWKwL"
                                    icon="icofont-facebook"
                                />
                                <SocialIcon
                                    path="https://instagram.com/sncom.in?igshid=NzZlODBkYWE4Ng=="
                                    icon="icofont-instagram"
                                />
                            </div> */}
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-6 offset-md-1 col-lg-3 offset-lg-0 col-xl-3 d-lg-none d-xl-block">
                        <div className="widget-item d-inline-block">
                            <h4 className="widget-title line-bottom mb-2">
                                Social Media Links
                            </h4>

                            <div className="widget-social-icons">
                                <SocialIcon
                                    path="https://twitter.com/"
                                    icon="icofont-twitter"
                                />
                                <SocialIcon
                                    path="https://myaccount.google.com/"
                                    icon="icofont-google-plus"
                                />
                                <SocialIcon
                                    path="https://www.youtube.com/@sncom500"
                                    icon="icofont-youtube-play"
                                />
                                {/* <SocialIcon
                                    path="https://rss.com/"
                                    icon="icofont-rss"
                                /> */}
                                <SocialIcon
                                    path="https://www.facebook.com/profile.php?id=100095220246316&mibextid=ZbWKwL"
                                    icon="icofont-facebook"
                                />
                                <SocialIcon
                                    path="https://instagram.com/sncom.in?igshid=NzZlODBkYWE4Ng=="
                                    icon="icofont-instagram"
                                />
                                {/* <SocialIcon
                                    path="https://dribbble.com/"
                                    icon="icofont-dribbble"
                                /> */}
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-sm-6 col-md-6 offset-md-1 col-lg-3 offset-lg-0 col-xl-3 d-lg-none d-xl-block">
                        <div className="widget-item d-inline-block">
                            <h4 className="widget-title line-bottom">
                                Popular Tags
                            </h4>
                            <div className="widget-tags">
                                <ul>
                                    <li>
                                        <Link to="/">Amazing</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Envato</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Themes</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Clean</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Wordpress</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Creative</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Mutilpurpose</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Retina Ready</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Twitter</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Responsive</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="col-sm-6 col-md-5 col-lg-4 col-xl-3">
                        <div className="widget-item">
                            <h4 className="widget-title line-bottom">
                                Recent Posts
                            </h4>
                            <nav className="widget-posts">
                                <ul className="posts-item">
                                    <li>
                                        <Link to="/">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Lorem Ispum dolor sit amet putilor
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Medical is all about quality.
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Is your website user friendly ?
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Ai offer weekly updates &amp; more.
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Customer should love your web.
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/">
                                            <i className="icon icofont-rounded-double-right"></i>
                                            Your site smooth and stunning.
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div> */}
                    
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-sm-12">
                            <div className="widget-copyright">
                                <p>
                                    &copy; 2023{" "}
                                    {/* <span className="text-uppercase"></span> */}
                                    Made with{" "}
                                    <i className="icofont-heart-alt"></i> by{" "}
                                    <a
                                        target="_blank"
                                        href="https://aasa.tech"
                                        rel="noreferrer"
                                        className="text-uppercase"
                                    >
                                        AASA Technologies
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
