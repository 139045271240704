import React, { useState, useEffect } from "react";
import "../styles/bookappointment.css";
import axios from "axios";
import toast from "react-hot-toast";
import { IoMdClose } from "react-icons/io";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Calendar from "react-calendar";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from "react-redux";
import fetchData from "../helper/apiCall";
import jwt_decode from "jwt-decode";
import TextField from '@mui/material/TextField';
import { setUserInfo } from "../redux/reducers/rootSlice";

axios.defaults.baseURL = process.env.REACT_APP_SERVER_DOMAIN;

const BookAppointment = ({ setModalOpen }) => {
  const [numberOfWeeks, changeNumberOfWeeks] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [reformattedDay, setReformattedDay] = useState(null);
  const [timeslotArr, setTimeslotArr] = useState([]);
  const [bookedTimeslotsArr, setBookedTimeslotsArr] = useState([]);
  const today = new Date();

  const dispatch = useDispatch();
  if(localStorage.getItem("token")) {
    const { userId } = jwt_decode(localStorage.getItem("token"));
  } else {
    const { userId } = "";
  }
  const [selectedSlotIndex, setSelectedSlotIndex] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  
  const [formDetails, setFormDetails] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confpassword: "",
    file: null,
    date: "",
    time: "",
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(() => {
  //   // const doctordata = fetchData(`http://localhost:5000/api/doctor/getdoctorbyid/${ele?.userId?._id}`);
  //   // console.log("doctordata_selectedTimeSlots======================",doctordata?.selectedTimeSlots);

  //   if(ele?.selectedTimeSlots) {
  //     // dispatch(setSelectedTimeSlots(doctordata?.selectedTimeSlots));
  //     setTimeslotArr(ele?.selectedTimeSlots.sort((a, b) => a.key - b.key));
  //   }
  //   // if(ele?.userId?.selectedTimeSlots.length > 0) {
  //   //   setTimeslotArr(ele?.userId?.selectedTimeSlots);
  //   // }
  // }, []);

  // const getUser = async () => {
  //   try {
  //     const temp = await fetchData(`http://localhost:5000/api/user/getuser/${userId}`);
      
  //   } catch (error) {}
  // };

  const inputChange = async(e) => {
    const { name, value } = e.target;

      // axios.post(
      //   "/appointment/getAppointmentsByDate",
      //   {
      //     date:value,
      //     doctorId:ele?.userId?._id
      //   },
      //   {
      //     headers: {
      //       authorization: `Bearer ${localStorage.getItem("token")}`,
      //     },
      //   }
      // ).then((data) => {
      //   let modifiedArray = data.data.map(obj => {
      //     return { timeslot: obj.timeslot };
      //   });
      //   let modifiedData = modifiedArray.map(item => item.timeslot);
      //   console.log("timeslotArr===============",timeslotArr);
      //   console.log("modifiedData===============",modifiedData);
      //   setBookedTimeslotsArr(modifiedData);

      //   for (let i = 0; i < timeslotArr.length; i++) {
      //     for (let j = 0; j < modifiedData.length; j++) {
      //         if (timeslotArr[i].startTime === modifiedData[j].startTime && timeslotArr[i].endTime === modifiedData[j].endTime) {
      //             timeslotArr[i].booking_status = 'booked';
      //             break; // No need to continue searching once we found a match
      //         }
      //     }
      //   }
      // })



    return setFormDetails({
      ...formDetails,
      [name]: value,
    });
  };

  const bookAppointment = async (e) => {
    e.preventDefault();
    try {
      if (token === "") {
        const { firstname, lastname, email, password, confpassword, file } = formDetails;
      
        if (!firstname || !lastname || !email || !password || !confpassword) {
          return toast.error("Input field should not be empty");
        } else if (firstname.length < 3) {
          return toast.error("First name must be at least 3 characters long");
        } else if (lastname.length < 3) {
          return toast.error("Last name must be at least 3 characters long");
        } else if (password.length < 5) {
          return toast.error("Password must be at least 5 characters long");
        } else if (password !== confpassword) {
          return toast.error("Passwords do not match");
        }
        
        // let formData = new FormData();
        // formData.append('file', file);
        // formData.append('firstname', firstname);
        // formData.append('lastname', lastname);
        // formData.append('email', email);
        // formData.append('password', password);
        // formData.append('date', formDetails.date);
        // formData.append('time', formDetails.time);
        
        // console.log("Formdata=============",formData);

        try {
          const response = await axios.post(
            "/appointment/newUserAppointment",
            {
              firstname: firstname,
              lastname: lastname,
              email: email,
              password: password,
              date: formDetails.date,
              time: formDetails.time
            },
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );

          if (response.data) {
            console.log("newUserAppointment_response==============",response);
            localStorage.setItem("token", response.data.loginToken);
            let decodedData = jwt_decode(response.data.loginToken);
            console.log("decodedData=================",decodedData);
            localStorage.setItem("firstName", decodedData?.userData?.firstname);
            localStorage.setItem("lastName", decodedData?.userData?.lastname);


            dispatch(setUserInfo(jwt_decode(response.data.loginToken).userId));
            getUser(jwt_decode(response.data.loginToken).userId);
          }
          toast.success("Appointment booked successfully");
          setModalOpen(false);

        } catch (error) {
          console.error('Error booking appointment:', error.response.data);
          setModalOpen(true);
          // Handle the error here
          toast.error(error?.response?.data);
        }

        // await toast.promise(
        //   const userAppointment = axios.post(
        //     "/appointment/newUserAppointment",
        //     {firstname:firstname, lastname:lastname, email:email, password:password, date:formDetails.date, time:formDetails.time },
        //     {
        //       headers: {
        //         Accept: "application/json",
        //         "Content-Type": "application/json",
        //       },
        //     }
        //   ),
        //   {
        //     success: "Appointment booked successfully",
        //     error: "Unable to book appointment",
        //     loading: "Booking appointment...",
        //   }
        // );
        // setModalOpen(false);
      } else {
        await toast.promise(
          axios.post(
            "/appointment/bookappointment",
            {
              // doctorId: ele?.userId?._id,
              date: formDetails.date,
              time: formDetails.time,
              // doctorname: `${ele?.userId?.firstname} ${ele?.userId?.lastname}`,
              // selectedSlot: selectedSlot
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          ),
          {
            success: "Appointment booked successfully",
            error: "Unable to book appointment",
            loading: "Booking appointment...",
          }
        );
        setModalOpen(false);
      }

      
    } catch (error) {
      return error;
    }
  };

  const getUser = async (id) => {
    try {
      const temp = await fetchData(`${process.env.REACT_APP_AUTH_URL}/api/user/getuser/${id}`);
      console.log("temp=========",temp);
      dispatch(setUserInfo(temp));
      window.location.reload();
      // return navigate("/");
    } catch (error) {
      return error;
    }
  };

  const handleSlotSelect = (slotData, index) => {
    if(slotData?.booking_status == "available") {
      console.log("slotData===========",slotData);
      setSelectedSlot(slotData);
      setSelectedSlotIndex(index);
    } else {
      console.log("SlotBooked===========");
    }
  }

  const onUpload = async (element) => {
    setFormDetails({ ...formDetails, file: element.target.files[0] });
  };

  return (
    <>
      <div className="modal flex-center">
        <div className="modal__content">
          <h2 className="page-heading">Book Appointment</h2>
          <IoMdClose
            onClick={() => {
              setModalOpen(false);
            }}
            className="close-btn"
          />
          <div className="register-container flex-center book">
            <form className="register-form">
              {
                !token ?
                <>
                  <input
                    type="text"
                    name="firstname"
                    className="form-input"
                    placeholder="Enter your first name"
                    value={formDetails.firstname}
                    onChange={inputChange}
                  />
                  <input
                    type="text"
                    name="lastname"
                    className="form-input"
                    placeholder="Enter your last name"
                    value={formDetails.lastname}
                    onChange={inputChange}
                  />
                  <input
                    type="email"
                    name="email"
                    className="form-input"
                    placeholder="Enter your email"
                    value={formDetails.email}
                    onChange={inputChange}
                  />
                  <input
                    type="file"
                    // onChange={(e) => onUpload(e.target.files[0])}
                    onChange={onUpload}
                    name="profile-pic"
                    id="profile-pic"
                    className="form-input"
                  />
                  <input
                    type="password"
                    name="password"
                    className="form-input"
                    placeholder="Enter your password"
                    value={formDetails.password}
                    onChange={inputChange}
                  />
                  <input
                    type="password"
                    name="confpassword"
                    className="form-input"
                    placeholder="Confirm your password"
                    value={formDetails.confpassword}
                    onChange={inputChange}
                  />
                </> : null
              }

              <div className="w-100">
                <label className="fontBold">Select Consulatation Date</label>
                <br />
                <input
                  type="date"
                  name="date"
                  className="form-input w-100"
                  value={formDetails.date}
                  onChange={inputChange}
                  placeholder="Select Booking Date"
                />
              </div>

              <div className="w-100">
                <label className="fontBold">Select Consulatation Time</label>
                <br />
                <input
                  type="time"
                  name="time"
                  className="form-input w-100"
                  value={formDetails.time}
                  onChange={inputChange}
                />
              </div>
              
              

              {/* <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ backgroundColor:'rgb(234, 234, 234)', borderRadius:'5px' }}
                >
                  <Typography>Select Time Slot</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ display:'grid', gridTemplateColumns:'1fr 1fr', gap:'10px', maxHeight:'25vh', overflow:'auto' }}>
                    {timeslotArr.map((val,idx)=>{
                      return(
                        <div onClick={()=>handleSlotSelect(val, idx)} className={`${val?.booking_status !== "available" ? 'slot-booked' : 'slot-available'} form-input`} style={{ backgroundColor: selectedSlotIndex == idx && val?.booking_status == "available" ? '#00abe4' : '', color: selectedSlotIndex == idx && val?.booking_status == "available" ? '#ffffff' : '', border:'1px solid', borderRadius:'8px', textAlign:'center', padding:'10px'}}>
                          {val?.startTime} - {val?.endTime}
                        </div>
                      )
                    })}
                  </div>
                </AccordionDetails>
              </Accordion> */}

              <button
                type="submit"
                className="btn form-btn"
                onClick={bookAppointment}
              >
                book
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookAppointment;
