import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../styles/register.css";
import axios from "axios";
import toast from "react-hot-toast";
// import Navbar from "../components/Navbar";
import Header from "../layout/header";
import Footer from "../layout/footer";

axios.defaults.baseURL = process.env.REACT_APP_SERVER_DOMAIN;

function Register() {
  // const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [formDetails, setFormDetails] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confpassword: "",
    file: null,
  });
  const navigate = useNavigate();

  const inputChange = (e) => {
    const { name, value } = e.target;
    return setFormDetails({
      ...formDetails,
      [name]: value,
    });
  };

  const onUpload = async (element) => {
    // setLoading(true);
    console.log("elements==============", element.target.files[0])
    setFormDetails({ ...formDetails, file: element.target.files[0] });
    // if (element.type === "image/jpeg" || element.type === "image/png") {
    //   const data = new FormData();
    //   data.append("file", element);
    //   data.append("upload_preset", process.env.REACT_APP_CLOUDINARY_PRESET);
    //   data.append("cloud_name", process.env.REACT_APP_CLOUDINARY_CLOUD_NAME);
    //   setFile(element);
    //   // fetch(process.env.REACT_APP_CLOUDINARY_BASE_URL, {
    //   //   method: "POST",
    //   //   body: data,
    //   // })
    //   //   .then((res) => res.json())
    //   //   .then((data) => setFile(data.url.toString()));
    //   setLoading(false);
    // } else {
    //   setLoading(false);
    //   toast.error("Please select an image in jpeg or png format");
    // }
  };

  const formSubmit = async (e) => {
    try {
      e.preventDefault();

      // if (loading) return;
      // console.log("Loadinddg---------", file);
      console.log("testttttt---------", loading);

      // if (file === "") return;

      const { firstname, lastname, email, password, confpassword, file } =
        formDetails;
      if (!firstname || !lastname || !email || !password || !confpassword) {
        return toast.error("Input field should not be empty");
      } else if (firstname.length < 3) {
        return toast.error("First name must be at least 3 characters long");
      } else if (lastname.length < 3) {
        return toast.error("Last name must be at least 3 characters long");
      } else if (password.length < 5) {
        return toast.error("Password must be at least 5 characters long");
      } else if (password !== confpassword) {
        return toast.error("Passwords do not match");
      } else if (!agreeTerms) {
        return toast.error("Please agree to the Terms and Conditions");
      }
      console.log("file--------------", file);

      let formData = new FormData();
      formData.append('file', file);
      formData.append('firstname', firstname);
      formData.append('lastname', lastname);
      formData.append('email', email);
      formData.append('password', password);

      console.log("Formdata=============", formData);

      await toast.promise(
        axios.post("/user/register", formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }),
        {
          pending: "Registering user...",
          success: "User registered successfully",
          error: "Unable to register user",
          loading: "Registering user...",
        }
      );
      return navigate("/login");
    } catch (error) { }
  };

  const [agreeTerms, setAgreeTerms] = useState(false);

  // Update the state when the checkbox is clicked
  const handleTermsChange = () => {
    setAgreeTerms(!agreeTerms);
  };

  return (
    <>
      <Header />
      <section className="register-section flex-center mt-5 mb-5">
        <div className="register-container flex-center">
          <h2 className="form-heading">Sign Up</h2>
          <form
            onSubmit={formSubmit}
            className="register-form"
          >
            <input
              type="text"
              name="firstname"
              className="form-input"
              placeholder="Enter your first name"
              value={formDetails.firstname}
              onChange={inputChange}
            />
            <input
              type="text"
              name="lastname"
              className="form-input"
              placeholder="Enter your last name"
              value={formDetails.lastname}
              onChange={inputChange}
            />
            <input
              type="email"
              name="email"
              className="form-input"
              placeholder="Enter your email"
              value={formDetails.email}
              onChange={inputChange}
            />
            {/* <input
              type="file"
              style={{ borderRadius: '0px' }}
              onChange={onUpload}
              name="profile-pic"
              id="profile-pic"
              className=""
            /> */}
            <input
              type="password"
              name="password"
              className="form-input"
              placeholder="Enter your password"
              value={formDetails.password}
              onChange={inputChange}
            />
            <input
              type="password"
              name="confpassword"
              className="form-input"
              placeholder="Confirm your password"
              value={formDetails.confpassword}
              onChange={inputChange}
            />
            <div>
              <input
              className="mr-2"
                type="checkbox"
                id="agreeTerms"
                name="agreeTerms"
                checked={agreeTerms}
                onChange={handleTermsChange}
              />
              <label htmlFor="agreeTerms">
                I agree to the {' '}
                <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                  <span>
                    Terms and Conditions
                  </span>
                </a>
              </label>
            </div>
            <button
              type="submit"
              className="btn primary-btn"
              disabled={loading ? true : false}
            >
              sign up
            </button>
          </form>
          <p>
            Already a user?{" "}
            <NavLink
              className="login-link primary-font"
              to={"/login"}
            >
              Log in
            </NavLink>
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Register;
