import "./styles/app.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Register from "./pages/Register";
import { Toaster } from "react-hot-toast";
import { Protected, Public, Admin } from "./middleware/route";
import React, { lazy, Suspense, useEffect, useState } from "react";
import Loading from "./components/Loading";
import VideoMeeting from "./meeting/VideoMeeting";
import io from 'socket.io-client';
import jwt_decode from "jwt-decode";
import "./assets/css/bootstrap.min.css";
import "./assets/scss/style.scss";
import "./assets/css/icofont.css";
import "./assets/css/animate.css";
import "swiper/components/navigation/navigation.scss";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import TawkTo from 'tawkto-react'
// import { Payment } from "@mui/icons-material";

const Home = lazy(() => import("./pages/Home"));
const TermsConditions = lazy(() => import("./pages/TermsConditions"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const RefundPolicy = lazy(() => import("./pages/RefundPolicy"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Appointments = lazy(() => import("./pages/Appointments"));
const ConsultantAppointments = lazy(() => import("./pages/DoctorAppointments"));
const Doctors = lazy(() => import("./pages/Doctors"));
const Profile = lazy(() => import("./pages/Profile"));
const Payments = lazy(() => import("./pages/Payments"));
const Notifications = lazy(() => import("./pages/Notifications"));
const ApplyDoctor = lazy(() => import("./pages/ApplyDoctor"));
const Error = lazy(() => import("./pages/Error"));
export const socket = io(`${process.env.REACT_APP_AUTH_URL}`);
// export const socket = io('http://localhost:5000');

function App() {
  const [user, setUser] = useState(
    localStorage.getItem("token")
      ? jwt_decode(localStorage.getItem("token"))
      : ""
  );
  
  useEffect(() => {
    var tawk = new TawkTo(process.env.REACT_APP_TAWK_PROPERTY_ID, process.env.REACT_APP_TAWK_ID);

    tawk.hideWidget()
    // console.log("User============",user);
    if(user?.isDoctor) {
      socket.on('newAppointment', (notification) => {
        console.log("permission===========",Notification.permission);
        console.log("newAppointment===========",notification);
        showNotification(notification?.content);
      });
      return () => {
        socket.disconnect();
      };
    }
  }, []);

  const showNotification = (message) => {
    // Display desktop notification
      if (Notification.permission === 'granted') {
        new Notification('New Notification', {
          body: message,
        });
      } else {
        console.log("requestPermission1===========");

        Notification.requestPermission().then((permission) => {
          console.log("requestPermission2===========",permission);
          if (permission === 'granted') {
            new Notification('New Message', {
              body: message,
            });
          }
        });
      }
  }

  useEffect(() => {
    console.log("usefect=================");
    socket.on('connection', () => {
      console.log('Connected to server');
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <Router>
      <Toaster />
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route
            path="/login"
            element={<Login />}
          />
          <Route
            path="/register"
            element={
              <Public>
                <Register />
              </Public>
            }
          />
          <Route
            path="/forgot-password"
            element={<ForgotPassword />}
          />
          <Route
            path="/reset-password/:token"
            element={<ResetPassword />}
          />
          <Route
            path="/"
            element={<Home />}
          />
          <Route
            path="/terms-and-conditions"
            element={<TermsConditions />}
          />
          <Route
            path="/privacy-policy"
            element={<PrivacyPolicy />}
          />
          <Route
            path="/refund-policy"
            element={<RefundPolicy />}
          />
          <Route
            path="/doctors"
            element={<Doctors />}
          />
          <Route
            path="/appointments"
            element={
              <Protected>
                <Appointments />
              </Protected>
            }
          />
          <Route
            path="/notifications"
            element={
              <Protected>
                <Notifications />
              </Protected>
            }
          />
          <Route
            path="/applyfordoctor"
            element={
              <Protected>
                <ApplyDoctor />
              </Protected>
            }
          />
          <Route
            path="/profile"
            element={
              <Protected>
                <Profile />
              </Protected>
            }
          />
          <Route
            path="/payments"
            element={
              <Protected>
                <Payments />
              </Protected>
            }
          />
          <Route
            path="/dashboard/users"
            element={
              <Admin>
                <Dashboard type={"users"} />
              </Admin>
            }
          />
          <Route
            path="/dashboard/doctors"
            element={
              <Admin>
                <Dashboard type={"doctors"} />
              </Admin>
            }
          />
          <Route
            path="/dashboard/appointments"
            element={
              <Protected>
                <Dashboard type={"appointments"} />
              </Protected>
            }
          />
          <Route
            path="/dashboard/applications"
            element={
              <Protected>
                <Dashboard type={"applications"} />
              </Protected>
            }
          />
          <Route
            path="/dashboard/consultant-appointments"
            element={
              <Protected>
                <Dashboard type={"consultant-appointments"} />
              </Protected>
            }
          />
          <Route
            path="/appointments/join-meeting"
            element={
              <Protected>
                <VideoMeeting />
              </Protected>
            }
          />
          <Route
            path="*"
            element={<Error />}
          />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
